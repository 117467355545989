.App {
  text-align: center;
}

.CorFundo {
  /* background-color: #292929; */
  background-color: #282c34;
  color: #fff
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.NavBar {
  /* Define as propriedade da navBar */
  padding: 1% !important;
  /* background-color: #0065d1 !important; */
  /* background-color: #1141ff !important; */
  background-color: #6c757d !important;
  /* position: fixed !important; */
  top: 0 !important;
  width: -webkit-fill-available !important;
  z-index: 1008 !important;
  color: #fff !important;
}

.corLink {
  color: #fff !important;
  padding-top: 0px !important;
}

.row {
  /* Retira as margens do component Row */
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.margemTop {
  margin-top: 2%;
}

.footer {
  /* background-color: #0065d1 */
  /* background-color: #1141ff !important; */
  background-color: #6c757d !important;
}

.margemNavlink {
  margin-left: 5%
}

.navbar-toggler-icon {
  border-color: white !important;
}

.tamanhoHeaderCard {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.resizeComentario {
  resize: none
}

.margemTextos {
  margin-top: 5%
}

.bordaLateral {
  border-right: 1px solid white;
}

.alinhamentoText {
  text-align: start;
}

.botaoTopo {
  /* Fixa o botão de voltar ao topo da tela de Politica de Privacidade */
  position: fixed;
}

.aumentaLetra {
  /* Aumenta o tamanho e deixa em negrito as letras do header */
  font-weight: bold;
  font-size: large;
}

@media (min-width: 1024px) {
  /* Faz o alinhamento dos botões de "Ver mais" da tela inicial */
  .alinhamentoBotao {
    min-width: 226px;
    min-height: 120px;
  }
}

.margem{
  /* border: 0.5px solid white; */
  margin: 1%;
  background-color: #6c757d;
  height: 280px;
}

.tamanhoLogo{
  max-width: 140px;
  max-height: 140px;
  width: auto;
  height: auto;
}

.expansaoDiv {
  /* Faz com que as divs de conteudo aumentem para que a cor de fundo seja aplicada para a tela toda */
  min-block-size: -webkit-fill-available;
}